import React, {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {
  QRTravelDeletemp3Req,
  QRTravelText2AudioReq,
  QRTravelTextAddReq,
  QRTravelTextUpdateReq,
  magicnum,
} from '../../../services/Request'
import {useTranslation} from 'react-i18next'
import {
  CDN_URL,
  QRTravelLangAdd,
  QRTravelLangDeletemp3,
  QRTravelText2Audio,
  QRTravelTextAdd,
  QRTravelTextGetItem,
  QRTravelTextTranslate,
  QRTravelTextUpdate,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {getLangCode, getLangName, isNullOrEmpty} from '../../../services/Utils'

interface IProps {
  item: any
  mainText: any
  success: any
}

const AddAudio: FC<IProps> = ({item, mainText, success}) => {
  const {t} = useTranslation()
  const [loadingTranslate, setLoadingTranslate] = useState(false)
  const [loadingGet, setLoadingGet] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingaudio, setLoadingaudio] = useState(false)
  const [text, setText] = useState('')
  const [textarea, setTextarea] = useState('')
  const [newitem, setNewitem] = useState<any>('')
  const [data, setData] = useState<any>(undefined)
  const [showPlayer, setShowPlayer] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  console.log(item)
  useEffect(() => {
    if (!isNullOrEmpty(item?.id)) {
      get()
    }
  }, [item])

  useEffect(() => {
    setTextarea(text)
  }, [text])

  useEffect(() => {
    setNewitem(item)
  }, [item])

  const get = () => {
    setLoadingGet(true)
    var req = QRTravelTextAddReq
    req.text.tid = item?.qrid
    req.text.lang = item?.lang
    QRTravelTextGetItem(req, onGetSuccess, onFailed, 'POST')
  }

  const onGetSuccess = (response: any) => {
    setLoadingGet(false)
    setText(response?.data?.text)
    setData(response?.data)
    // settextinfo(response?.data)
  }

  const addAudio = () => {
    setLoadingaudio(true)
    var req = QRTravelText2AudioReq
    req.travel.id = item?.id
    req.travel.text = textarea
    req.travel.lang = getLangCode(item?.lang)
    req.travel.old_audio_url = item?.audio_url
    QRTravelText2Audio(req, onAudioSuccess, onFailed, 'POST')
  }
  const onAudioSuccess = (response: any) => {
    toast.success('Амжилттай audio үүслээ')
    setLoadingaudio(false)
    success()
  }

  const onFailed = (error: string) => {
    setLoading(false)
    setLoadingGet(false)
    return toast.error('error')
  }

  const tranlate = () => {
    setLoadingTranslate(true)
    var req = QRTravelText2AudioReq
    req.travel.id = item?.qrid
    req.travel.text = mainText?.text
    req.travel.lang = item?.lang
    QRTravelTextTranslate(req, onTranslateSuccess, onFailed, 'POST')
  }
  const onTranslateSuccess = (response: any) => {
    setLoadingTranslate(false)
    toast.success('Амжилттай translate хийлээ')
    success()
  }

  const updateText = () => {
    if (!isNullOrEmpty(textarea)) {
      setLoading(true)
      var req = QRTravelTextUpdateReq
      req.text.id = data?.id
      req.text.text = textarea
      QRTravelTextUpdate(req, onUpdateSuccess, onFailed, 'PUT')
    } else {
      return toast.warning('Утга оруулна уу!')
    }
  }
  const addText = () => {
    if (!isNullOrEmpty(textarea)) {
      setLoading(true)
      var req = QRTravelTextAddReq
      req.text.tid = item?.qrid
      req.text.text = textarea
      req.text.lang = newitem?.lang
      QRTravelTextAdd(req, onUpdateSuccess, onFailed, 'POST')
    } else {
      return toast.warning('Утга оруулна уу!')
    }
  }

  const onUpdateSuccess = (response: any) => {
    setLoading(false)
    success()
    return toast.success(t('common:successUpdate'))
  }

  const deletemp3 = () => {
    if (!isNullOrEmpty(text)) {
      setLoadingDelete(true)
      setNewitem([])
      var req = QRTravelDeletemp3Req
      req.qr.id = item?.id
      QRTravelLangDeletemp3(req, onDeleteSuccess, onFailed, 'PUT')
    } else {
      return toast.warning('Утга оруулна уу!')
    }
  }

  const onDeleteSuccess = (response: any) => {
    setLoadingDelete(false)
    success()
    return toast.success('Successfully deleted')
  }
  const copyToClipboard = () => {
    navigator.clipboard.writeText(mainText?.text).then(() => {
      toast.success('Successfully copied')
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  return (
    <div className='p-10 flex flex-col gap-7'>
      <h1>{getLangName(item?.lang)}</h1>
      <div style={{position: 'relative'}}>
        <span>Main text: {mainText?.text.slice(0, 300)}</span>
        <i onClick={copyToClipboard} className="fa-regular fa-copy cursor-pointer"></i>
        <a
          style={{position: 'absolute', right: '0', top: '-40px'}}
          target='_blank'
          href='https://cloud.google.com/text-to-speech/docs/ssml'
        >
          https://cloud.google.com/text-to-speech/docs/ssml
        </a>
      </div>

      <div style={{width: '1200px', height: '500px'}}>
        <span>Text in {getLangName(item?.lang)}:</span>
        <textarea
          onChange={(e) => setTextarea(e.target.value)}
          value={textarea}
          className='w-100 px-2 py-1'
          name=''
          id=''
          style={{height: '95%'}}
        ></textarea>
      </div>
      <div className='flex justify-content-between'>
        <div className='flex gap-2 items-center'>
          <button
            disabled={loadingTranslate}
            onClick={() => tranlate()}
            className='btn btn-light-success h-40px flex items-center'
          >
            {loadingTranslate ? 'Translating' : ' Translate from main'}
          </button>
          {!isNullOrEmpty(newitem.audio_url) ? (
            <>
              <button
                onClick={() => setShowPlayer(!showPlayer)}
                className='btn btn-light-info h-40px gap-2 flex items-center justify-center'
              >
                <i className='fa-solid fa-play'></i> play
              </button>
              {showPlayer ? <audio autoPlay controls src={CDN_URL + item?.audio_url} /> : null}
            </>
          ) : null}

          {!isNullOrEmpty(newitem.audio_url) ? (
            <button
              onClick={() => deletemp3()}
              className='btn btn-light-info h-40px flex items-center justify-center'
              disabled={loadingDelete}
            >
              <KTIcon iconName='trash' className='fs-3' />
              <span>{loadingDelete ? 'deleting' : 'delete mp3'}</span>
            </button>
          ) : null}
        </div>
        <div className='flex gap-4'>
          <button
            disabled={loading}
            onClick={isNullOrEmpty(text) ? addText : updateText}
            className='btn btn-light-info h-40px flex items-center justify-center'
          >
            {loading ? 'loading' : 'save translate'}
          </button>
          <button
            disabled={loadingaudio}
            onClick={() => addAudio()}
            className='btn btn-info h-40px flex items-center'
          >
            {loadingaudio ? 'loading' : 'convert mp3'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddAudio
