import React, {FC, useEffect, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {QRTravelTextAdd, QRTravelTextGetItem, QRTravelTextUpdate} from '../../../services/main'
import {QRTravelTextAddReq, QRTravelTextGetItemReq, QRTravelTextUpdateReq} from '../../../services/Request'
import {isNullOrEmpty} from '../../../services/Utils'
interface IProps {
  item: any
  settextinfo: any
}
const TravelInfo: FC<IProps> = ({item, settextinfo}) => {
  const [loadingList, setLoadingList] = useState(false)
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState('')
  const [data, setData] = useState<any>(undefined)
  const {t} = useTranslation()

  useEffect(() => {
    if (!isNullOrEmpty(item?.id)) {
      get()
    }
  }, [item])

  const get = () => {
    setLoadingList(true)
    var req = QRTravelTextGetItemReq
    req.text.tid=item?.id
    req.text.lang='aaa'
    QRTravelTextGetItem(req, onGetSuccess, onFailed, 'POST')
  }

  const onGetSuccess = (response: any) => {
    setLoadingList(false)
    setText(response?.data?.text)
    setData(response?.data)
    settextinfo(response?.data)
  }

  const add = () => {
    if (!isNullOrEmpty(text)) {
      setLoading(true)
      var req = QRTravelTextAddReq
      req.text.tid = item?.id
      req.text.text = text
      req.text.lang = 'aaa'
      QRTravelTextAdd(req, onSuccess, onFailed, 'POST')
    } else {
      return toast.warning('Утга оруулна уу!')
    }
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    get()
    return toast.success(t('common:successCreate'))
  }

  const update = () => {
    if (!isNullOrEmpty(text)) {
      setLoading(true)
      var req = QRTravelTextUpdateReq
      req.text.id = data?.id
      req.text.text = text
      QRTravelTextUpdate(req, onUpdateSuccess, onFailed, 'PUT')
    } else {
      return toast.warning('Утга оруулна уу!')
    }
  }

  const onUpdateSuccess = (response: any) => {
    setLoading(false)
    get()
    return toast.success(t('common:successUpdate'))
  }

  const onFailed = (error: string) => {
    setLoading(false)
    setLoadingList(false)
    return toast.error(error)
  }

  return (
    <div className='edit-modal mobile:w-full w-[600px]'>
      <textarea rows={20} cols={150} value={text} onChange={(e) => setText(e.target.value)} />
      <div className='flex flex-col gap-3'>
        <div
          onClick={() => {
            isNullOrEmpty(data) ? add() : update()
          }}
          className='btn btn-primary align-self-center'
        >
          <span>Хадгалах</span>
        </div>
      </div>
    </div>
  )
}

export default TravelInfo
