import React, {FC, useEffect, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {KTIcon} from '../../../_metronic/helpers'
import {isNullOrEmpty} from '../../../services/Utils'
import {
  QRTravelLangAdd,
  QRTravelLangDelete,
  QRTravelLangUpdate,
  QRTravelText2Audio,
} from '../../../services/main'
import {
  BaseRequest,
  QRTravelText2AudioReq,
  addQRTravelLangReq,
  editQRTravelLangReq,
  magicnum,
} from '../../../services/Request'
import {useParams} from 'react-router-dom'
import CustomModal from '../../modules/CustomModal/modal'
import AddAudio from './addAudio'

interface IProps {
  langs: any
  success: any
  text: any
}
const TravelLang: FC<IProps> = ({langs, success, text}) => {
  const {id} = useParams()
  const [data, setData] = useState<any>([])
  const [loadingadd, setLoadingadd] = useState(false)
  const [rows, setRows] = useState<any>([{id: '1', lang: '', link: ''}])
  const [loading, setLoading] = useState(false)
  const [loadingaudio, setLoadingaudio] = useState(false)
  const [showModal, updateShowModal] = useState(false)
  const [withmp3, setWithmp3] = useState(false)
  const [item, setItem] = useState<any>('')

  useEffect(() => {
    if (langs.length > 0) {
      setData(langs)
    }
  }, [langs])

  const edit = (item: any) => {
    setLoading(true)
    var req = editQRTravelLangReq
    req.qr.id = item.id
    req.qr.url = item.url
    QRTravelLangUpdate(req, onSuccess, onFailed, 'PUT')
  }
  const add = (id: any) => {
    setLoadingadd(true)
    setRows([{id: '1', lang: '', link: ''}])
    var req = addQRTravelLangReq
    req.qr.langs = rows
    if (!isNullOrEmpty(id)) {
      req.qr.id = id
    }
    QRTravelLangAdd(req, onSuccess, onFailed, 'POST')
  }
  
  const onSuccess = (response: any) => {
    toast.success('Амжилттай')
    setLoading(false)
    setLoadingadd(false)
    success()
  }
  
  const deleteFunc = (el: any) => {
    QRTravelLangDelete(el, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = (response: any) => {
    success()
    toast.success('Амжилттай устгагдлаа')
  }
  const onFailed = (error: any) => {
    setLoadingaudio(false)
    setLoading(false)
    setLoadingadd(false)
    console.log('error:', error)
  }
  const addRow = () => {
    const newRow = {id: rows.length + 1, lang: '', link: ''}
    setRows([...rows, newRow])
  }
  const deleteRow = (id: any) => {
    setRows(rows.filter((row: any) => row.id !== id))
  }
  const changeLang = (value: any, id: any) => {
    var tempMas: any = []
    rows.map((uu: any) => {
      var tempobj = uu
      if (uu.id == id) {
        tempobj.lang = value
      }
      tempMas.push(tempobj)
    })
    setRows(tempMas)
  }
  const changeLink = (value: any, id: any) => {
    var tempMas: any = []
    rows.map((uu: any) => {
      var tempobj = uu
      if (uu.id == id) {
        tempobj.link = value
      }
      tempMas.push(tempobj)
    })
    setRows(tempMas)
  }
  const handleChange = (item: any, event: any) => {
    const newValue = event.target.value
    const updatedarr = data.map((el: any) => {
      return el.id == item.id ? {...el, url: newValue} : el
    })
    setData(updatedarr)
  }
  const issetLang = (lang: any) => {
    var isset = false
    data.map((el: any) => {
      if (el?.lang == lang) {
        isset = true
        return
      }
    })
    return isset
  }

  return (
    <>
      <div className='edit-modal mobile:w-full w-800px' style={{marginLeft: '20px'}}>
        <div className='lang-mid'>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>
                  <span className='w-100% flex justify-center'>Хэл</span>
                </th>
                <th scope='col' style={{borderLeft: '1px solid #eff2f6'}}>
                  <span className='w-100% flex justify-center'>Унших</span>
                </th>
                <th scope='col' style={{borderLeft: '1px solid #eff2f6'}}>
                  <span className='w-100% flex justify-center'>Аудио</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item: any) => {
                return (
                  <tr className=''>
                    <td>
                      <div className='flex items-center justify-center' style={{height: '40px'}}>
                        <img width={28} src={`/flags/${item?.lang}.svg`} alt='' />
                      </div>
                    </td>
                    <td style={{borderLeft: '1px solid #eff2f6'}} className='flex gap-2'>
                      <input
                        className='form-control form-control-sm form-control-solid h-40px'
                        type='text'
                        value={item?.url}
                        onChange={(event) => {
                          handleChange(item, event)
                        }}
                      />
                      <button
                        style={{padding: '0px', minWidth: '40px'}}
                        className='btn btn-light-primary flex items-center h-40px flex justify-center'
                        disabled={loading}
                        onClick={() => edit(item)}
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </button>
                      <button
                        style={{padding: '0px', minWidth: '40px'}}
                        className='btn btn-light-danger flex items-center h-40px flex justify-center'
                        onClick={() => deleteFunc(item.id)}
                      >
                        <KTIcon iconName='trash' className='fs-2' />
                      </button>
                    </td>
                    <td style={{borderLeft: '1px solid #eff2f6'}}>
                      <button
                        onClick={() => {
                          updateShowModal(true)
                          setItem(item)
                        }}
                        className={`btn h-40px w-40px flex items-center justify-center ${
                          item.audio_url ? 'btn-success' : 'btn-secondary'
                        } `}
                      >
                        <i style={{padding: '0'}} className='fa-solid fa-play'></i>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <div className='edit-title'>
            <span>Шинээр хэл нэмэх</span>
          </div>
          <div className='rows'>
            {rows.map((item: any) => {
              return (
                <div className='flex flex-col gap-2'>
                  <div key={item.id} className='bow-row'>
                    <select
                      className='form-select form-select-sm form-select-solid cursor-pointer'
                      value={item.lang}
                      onChange={(e) => {
                        changeLang(e.target.value, item.id)
                      }}
                    >
                      <option value=''>Хэлээ сонго</option>
                      {magicnum.travelLanguage.map((el, index) => {
                        return !issetLang(el.short_code) ? (
                          <option key={index} value={el.short_code}>{el.name}</option>
                        ) : null
                      })}
                    </select>
                    <input
                      className='form-control form-control-sm form-control-solid'
                      type='text'
                      value={item.link}
                      placeholder='Flipbook холбоос'
                      onChange={(e) => {
                        changeLink(e.target.value, item.id)
                      }}
                    />

                    <button
                      className='btn btn-light-danger flex items-center h-40px px-6 '
                      onClick={() => deleteRow(item.id)}
                    >
                      <KTIcon iconName='trash' className='fs-4' />
                    </button>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className='flex flex-col gap-3'>
          <div className='w-full flex justify-content-end'>
            <button onClick={addRow} className='btn btn-secondary'>
              <span>Мөр нэмэх</span>
            </button>
          </div>

          <button
            disabled={loadingadd}
            onClick={() => add(id)}
            className='btn btn-primary w-full text-white'
          >
            <span>{loadingadd ? 'loading' : 'Хадгалах'}</span>
          </button>
        </div>
      </div>

      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <AddAudio item={item} mainText={text} success={success}/>
        </CustomModal>
      ) : null}
    </>
  )
}

export default TravelLang
